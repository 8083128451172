import React, { useEffect } from 'react';

const Hero = () => {
  useEffect(() => {
    const handleParallax = (e: MouseEvent) => {
      const shapes = document.querySelectorAll('.shape');
      const x = e.clientX / window.innerWidth;
      const y = e.clientY / window.innerHeight;
      
      shapes.forEach((shape, index) => {
        const speed = (index + 1) * 20;
        const translateX = (x - 0.5) * speed;
        const translateY = (y - 0.5) * speed;
        
        (shape as HTMLElement).style.transform = 
          `translate(${translateX}px, ${translateY}px)`;
      });
    };

    window.addEventListener('mousemove', handleParallax);
    return () => window.removeEventListener('mousemove', handleParallax);
  }, []);

  return (
    <section className="hero">
      <div className="animated-shapes">
        <div className="shape shape1"></div>
      </div>
      <div className="hero-background"></div>
      <div className="hero-content">
        <div className="ai-badge">
          AI-Powered Mental Wellness
        </div>
        
        <div className="hero-text">
          <h1>
            <span className="gradient-text">Elevate</span> Your Mental<br />Wellness Journey
          </h1>
          <p className="value-proposition">
            Need someone to talk to? Chat with <span className="gradient-text">Bright</span> now—your AI companion for instant, personalized support, with professional care just a tap away. Private, secure, and always here.
          </p>
          <div className="reassurance-tags">
            <span className="reassurance-tag">
              <svg viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 2L2 7v10l10 5 10-5V7L12 2zm0 18l-8-4V8l8 4v8z"/>
              </svg>
              100% Confidential
            </span>
            <span className="reassurance-tag">
              <svg viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z"/>
              </svg>
              You're Always in Control
            </span>
          </div>
        </div>

        <div className="cta-section">
          <div className="cta-group">
            <button className="cta-button primary">
              Begin Your Journey
              <span className="button-icon">→</span>
            </button>
          </div>
          <p className="cta-note">Free 14-day trial • No credit card needed</p>
        </div>
      </div>
    </section>
  );
};

export default Hero;