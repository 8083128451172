import React, { useState, useEffect } from 'react';
import '../../styles/components/Navbar.css'; 

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 50; // Adjust this value as needed
      
      if (window.scrollY > scrollThreshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
      <div className="logo">BrightLyf</div>
      <div className="nav-links">
        <a href="#professionals">Professionals</a>
        <a href="#institutions">Institutions</a>
      </div>
    </nav>
  );
};

export default Navbar;