import React from 'react';
import Navbar from './components/layout/Navbar';
import Hero from './components/sections/Hero';
import './styles/components/Navbar.css';
import './styles/components/Hero.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
    </div>
  );
}

export default App;
